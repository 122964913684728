<template>
  <dashboard>
    
    <el-row :gutter="15">
      <el-col :xs="24">
        <div class="jobfeed">

          <el-card class="addon pb-2" :body-style="{ padding: '0px' }">
            <h4 class="m-3 bold orange-text">Billing</h4>
            <hr>
            <!-- <div class="header"></div> -->
            <div class="body px-3 py-4">
              <div class="details pt-0">
                <h5 class="orange-text bold mb-3">Your current plan and subscription details</h5>
                <div class="description">
                  <subscription @subscriptionUpdate="updateSubscription" :url="url" :subscription="subscription"/>
                  <el-alert class="mt-4" v-show="success"
                            title="Your Subscription is now upgraded."
                            type="success"
                            effect="dark"
                            show-icon>
                  </el-alert>
                  <!-- <el-button type="text" @click="upgradeModal = true">Click to open the Dialog</el-button> -->
                </div>
              </div>
            </div>

            <!-- Upgrade modal -->
            <el-dialog
              :visible.sync="showSubscriptionModal"
              width="650px"
              center>
               <span slot="title" class="dialog-title">
                <h4 class="orange-text bold mb-2">Update
                                                  Subscription
                </h4>
              </span>
              <p class="orange-text bold mb-3 text-left"><i class="el-icon-bank-card"></i> Payment information</p>
                <update-subscription
                        :subscription="subscription"
                        :current-card="subscription.card"
                        v-on:upgrade-subscription="upgradeSuccess"
                ></update-subscription>
<!--              <CardUPdate @cardUpdated="updateCard"-->
<!--                          :current-card="subscription.card" :modal="true" :url="url"/>-->
<!--              <h5 class="mb-2 mt-3 orange-text bold">Billing Information</h5>-->
<!--              <billing-information-->
<!--                      :current-card="subscription.card"></billing-information>-->
            </el-dialog>

            <!-- Use when employer has a paid subcription -->
            <div class="body pt-0 pb-0"
                 v-if="subscription.card">
              <div class="details pt-0">
                <hr class="mt-0 mb-4" style="border-color: lightgray">
                <h5 class="orange-text bold mb-4">Card Information</h5>
                <p>Credit Card Type: {{
                                subscription.card.card_brand }}</p>
                <p>Card No.:
                                xxxx-xxxx-xxxx-{{
                                subscription.card.card_last_digits }}</p>
                <p class="orange-text bold mb-3"><i
                        class="el-icon-bank-card"></i>
                  Input New
                                                       card information</p>
                <CardUPdate @cardUpdated="updateCard" :current-card="subscription.card" :url="url"/>
                <el-alert class="mt-4" v-show="cardSuccess"
                          title="Your Card is now updated"
                          type="success"
                          effect="dark"
                          show-icon>
                </el-alert>
              </div>
            </div>
<!--            <div class="body pt-0 pb-0"-->
<!--                 v-if="subscription.card">-->
<!--              <div class="details pt-0">-->
<!--                <hr class="mt-0 mb-4" style="border-color: lightgray">-->
<!--                <h5 class="mb-4 orange-text bold">Billing Information</h5>-->
<!--                <p class="mt-0 mb-3 orange-text bold "><i class="el-icon-office-building"></i> Update your Billing Address</p>-->
<!--                <billing-information></billing-information>-->
<!--              </div>-->
<!--            </div>-->
            <div class="body pt-0 pb-0"
                 v-if="subscription.card && subscription.id">
              <div class="details pt-0">
                <cancel-subcription @subscriptionUpdate="updateCancelSubscription" :url="url" :subscription="subscription"/>
                <el-alert class="mt-4" v-show="cancelSuccess"
                          :title="`Your Subscription is now ${subscription.subscription_status}`"
                          type="success"
                          effect="dark"
                          @close="cancelSuccess = false"
                          show-icon>
                </el-alert>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
  import Dashboard from '~/components/Dashboard/Dashboard'
  import axios from "axios";
  import {mapGetters} from 'vuex'
  import * as constant from "../../vuex/utils/constant";
  import Subscription from "../../components/Subscription";
  import CardUPdate from "../../components/CardUPdate";
  import CancelSubcription from "../../components/CancelSubcription";
  // import BillingInformation from "../../components/BillingInformation";
  import UpdateSubscription
    from "../../components/UpdateSubscription";
  let user = JSON.parse(localStorage.getItem('user'));

  export default {
    components: {
      Dashboard,
      Subscription,
      CardUPdate,
      CancelSubcription,
      // BillingInformation,
      UpdateSubscription
    },
    mounted() {
      this.fetchSubscription();
    },
    data () {
      return {
        showSubscriptionModal: false,
        subscription: {
          card: null,
          plan_type: 'Silver',
          subscription_type: 'monthly'
        },
        card: {},
        url: constant.EMPLOYER,
        success: false,
        cardSuccess: false,
        cancelSuccess: false,
      }
    },
    computed:{
      ...mapGetters({
        info:'getEmployerInfo'
        , error: 'getEmployerErrResp'
        , success: 'getEmployerSuccessResp'
        , status: 'authStatus'
      }),
    },
    methods: {
      fetchSubscription() {
        axios.get(constant.LOCAL_BASE_URL + constant.EMPLOYER + '/get/subscription',{
          headers: {
            Authorization: 'Bearer ' + user.authorization.access_token
          }
        }).then(response => {
          this.subscription = response.data;
          this.subscription.subscription_type = 'monthly';
        })
      },
      updateSubscription() {
        if(!this.subscription.subscription ||
                !this.subscription.id){
          this.showSubscriptionModal = true;
        } else {
          this.showSubscriptionModal = false;
          this.success = true;
          this.fetchSubscription();
        }
      },
      updateCard() {
        this.fetchSubscription();
        this.cardSuccess = true
      },
      updateCancelSubscription() {
        this.fetchSubscription();
        this.cancelSuccess = true
      },
      upgradeSuccess() {
        this.showSubscriptionModal = false;
        this.success = true;
        this.fetchSubscription();
      }
    }
  }
</script>

<style lang="scss">

</style>
