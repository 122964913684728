<template>
    <el-row :gutter="15" style="user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */">
        <loading-page :active.sync="isLoading" />
        <el-col :xs="14" :md="17" v-if="subscription.has_other_subscription">
            <p class="bold mb-0">Subscription:
                                 {{subscription.other_subscription.subscription}}</p>
            <p class="bold mb-0">Plan: {{subscription.other_subscription.plan}}</p>
            <p v-if="subscription.coupons"
               class="bold mb-0">Coupon:
                                          {{subscription
                                 .coupons[0].name}}</p>
            <p v-if="subscription.other_subscription.id"
               class="small mt-1 mb-0 gray-text">Next
                                                  Billing Date: {{subscription.other_subscription.to}}</p>
            <p v-else class="small mt-1 mb-0 gray-text"
            >Your plan will be converted to a
             limited free plan on {{
             subscription.other_subscription.to
             }} when your special offer expires.</p>
        </el-col>

<!--        Display for paid/promo subscription-->
        <el-col :xs="14" :md="17"
                v-if="subscription.subscription">
            <p v-if="subscription.id"
               class="bold mb-0">Subscription: {{subscription.subscription}}</p>
            <p v-else class="bold mb-0">Subscription:
                                 Special Offer</p>
            <p class="bold mb-0">Status: {{
                                 subscription.subscription_status ? subscription.subscription_status : 'Active' | ucFirst}}</p>
            <p class="bold mb-0">Plan: {{subscription.plan}}</p>
            <p v-if="subscription.coupons"
               class="bold mb-0">Coupon:
                                 {{subscription
                                 .coupons[0].name}}</p>
            <div v-if="subscription.has_other_subscription">
                <p class="small mt-1 mb-0 gray-text">Last Day of Subscription: {{subscription.last_subscription_date}}</p>
            </div>
            <div v-else>
                <p v-if="subscription.id && subscription.to"
                   class="small mt-1 mb-0 gray-text"
                >Next Billing Date: {{subscription.to}}</p>
                <p v-else class="small mt-1 mb-0 gray-text"
                >Your plan will be converted to a
                 limited free plan on {{ subscription.to
                 }} when your special offer expires.</p>
            </div>
        </el-col>

<!--        Display for trial subscription-->
        <el-col v-else :xs="14" :md="17">
            <p class="bold mb-0">Subscription: Free</p>
            <p class="bold mb-0">Status: Active</p>
            <p class="bold mb-0">Plan: Basic</p>
        </el-col>

        <el-col :xs="24" :md="7" class="text-center mt-3"
                v-if="!subscription.has_other_subscription">
            <div v-if="subscription.subscription === 'Monthly'">
                <!-- //Price must be on Plan model  hardcode for now  -->
                <!-- <div class="amount-card">
                    <h3 class="text-center">$690.00</h3>
                </div> -->
                <el-button v-if="disabled &&
                subscription.id"
                           type="success" class="mt-2" @click.prevent="updateSubscription(subscription.id)">Upgrade to Yearly</el-button>
                <el-button
                        v-else-if="disabled &&
                        !subscription.id" type="success"
                           class="mt-0"
                           @click.prevent="updateSubscription">Upgrade Subscription</el-button>
                <p v-else class="text-center mb-0 gray-text">Upgrading disabled please resume subscription</p>

            </div>
            <div v-else-if="!subscription.subscription">
                <!-- <div  class="amount-card">
                    //Price must be on Plan model  hardcode for now 
                    <h3 class="text-center">$69.00</h3>
                </div> -->
                <el-button v-if="disabled" type="success"
                           class="mt-0"
                           @click.prevent="updateSubscription">Upgrade Subscription</el-button>
                <p v-else class="text-center mb-0 gray-text">Upgrading disabled please resume subscription</p>
            </div>
            <div v-else>
                <!-- <div  class="amount-card">
                    //Price must be on Plan model  hardcode for now 
                    <h3 class="text-center">$69.00</h3>
                </div> -->
                <el-button v-if="disabled" type="success" class="mt-2" @click.prevent="updateSubscription(subscription.id)">Downgrade to Monthly</el-button>
                <p v-else class="text-center mb-0 gray-text">Upgrading disabled please resume subscription</p>
            </div>

        </el-col>
    </el-row>
</template>
<script>
import axios from "axios";
import * as constant from "../vuex/utils/constant";
import LoadingPage from "./LoadingPage";
export default {
    name: 'Subscription',
    props: {
        subscription: {
            type: Object,
            require: true,
        },
        url: {
            type: String,
            required: true
        }
    },
    data() {
      return {
          isLoading: false
      }
    },
    computed: {
        disabled() {
            if(this.subscription.subscription_status === 'paused')
                return false
            return true
        }
    },
    filters: {
        ucFirst: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    components: {
        LoadingPage
    },
    methods: {
        updateSubscription(subscriptionId) {
            if(!this.subscription.subscription ||
                !this.subscription.id){
                this.$emit('subscriptionUpdate')
            } else {
                this.isLoading = true;
                let user = JSON.parse(localStorage.getItem('user'));
                axios.post(constant.LOCAL_BASE_URL + this.url + '/upgrade/subscription/' + subscriptionId, this.subscription,{
                    headers: {
                        Authorization: 'Bearer ' + user.authorization.access_token
                    }
                }).then((response) => {
                    this.isLoading = false;
                    this.$emit('subscriptionUpdate', response.data)
                })
            }
        }
  
    },
}
</script>
<style scoped>
    .amount-card {
        padding: 10px 15px;
        border: 2px solid #E87722;
        margin: auto;
        border-radius: 5px;
        max-width: 200px;
        width: 100%;
    }
</style>
