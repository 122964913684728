<template>
    <div class="el-form">
        <!-- Please show only if there is already existing information-->
        <!-- <p class="small mt-1 mb-0 gray-text">Credit Card Type: {{currentCard.card_brand}}</p>
        <p class="small mt-1 mb-4 gray-text">Card No: xxxx-xxxx-xxxx-{{currentCard.card_last_digits}}</p> -->
        <loading-page :active.sync="isLoading" />
        <el-form ref="form" >
            <el-row :gutter="20">
                <el-col :xs="24">
                    <el-form-item label="Name on Card" prop="card_name">
                        <el-input v-model="cardHolderName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="Credit Card Number" prop="card_number">
                        <div class="card-input" id="card-number"></div>
                        <div class="form-error" v-show="invalidCard">
                            Invalid card.
                        </div>
                        <div id="card-image" :class="cardType"></div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="24" :md="8">
                    <el-form-item label="Expiry Date"  class="card-info">
                        <div class="card-input" id="expiration-date"></div>
                        <div class="form-error" v-show="invalidExpiration">
                            Invalid expiration date.
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :md="8">
                    <el-form-item label="CVV" class="card-info" >
                        <div class="card-input" id="cvv"></div>
                        <div class="form-error" v-show="invalidCvv">
                            Invalid CVV.
                        </div>
                    </el-form-item>
                </el-col>
                <!-- Remove postal code here and use the one from Billing -->
                <el-col :xs="24" :md="8">
                    <el-form-item label="Zip Code"
                                  prop="postal_code">
                        <div class="card-input" id="postal-code"></div>
                        <div class="form-error" v-show="invalidPostal">
                            Invalid Zip.
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="24" class="text-right mt-3 mb-3">
                    <el-button type="success" class="" @click.prevent="updateCard">Update Card</el-button>
                </el-col>
            </el-row>
        </el-form>
   </div>
</template>
<script>
import axios from "axios";
import {client, hostedFields} from "braintree-web";
import {LOCAL_BASE_URL, BILLING_TOKEN} from '../vuex/utils/constant'
import LoadingPage from "./LoadingPage";

export default {
    name: 'UpdateCard',
    created() {
        this.init();
    },
    components: {
        LoadingPage,
    },
    props: {
        url: {
            type: String,
            require: true
        },
        currentCard: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            cardName: '',
            vaultedPaymentMethods: null,
            hostedFieldsInstance: null,
            tokenizePayload: null,
            card: '',
            invalidCard: false,
            invalidCvv: false,
            invalidExpiration: false,
            invalidPostal: false,
            cardIsValid: false,
            cardHolderName: null,
            token: null
        }
    },
    computed: {
        cardType() {
            return this.card
        },
    },
    methods: {
        init () {
            axios.get(LOCAL_BASE_URL + BILLING_TOKEN)
                .then(response => {
                    client.create({
                        authorization: response.data.token
                    }, (clientErr, clientInstance) => {
                        if (clientErr) {
                            this.errorMessage = `There was an error setting up the client instance. Message: ${clientErr.message}`;
                            this.$emit('braintreeError', this.errorMessage);
                            return;
                        } else {
                            this.clientInstance = clientInstance
                            this.createField();
                        }
                    });
                })
        },
        createField: function () {
            hostedFields.create({
                client: this.clientInstance,
                styles: {
                    'input, select': {
                        'border-radius': '4px',
                        'font-size': '14px',
                        'height': '40px',
                        'color': '#606266'
                    },
                    ':focus': {
                        'color': '#606266',
                    },
                    '.invalid': {
                        'color': '#EB5757'
                    }

                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: '•••• •••• •••• ••••',
                    },
                    cvv: {
                        selector: '#cvv',
                        placeholder: '123'
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: 'MM/YY'
                    },
                    postalCode: {
                        selector: '#postal-code',
                        placeholder: '6000'
                    }
                }
            }, (fieldsError, hostedFieldsInstance) => {
                hostedFieldsInstance.on('cardTypeChange', (event) => {
                    try {
                        this.card = event.cards[0].type;
                        // this.cardName = event.cards[0].niceType;
                    } catch (e) {
                        this.card = '';
                        // this.cardName = '';
                    }

                });
                hostedFieldsInstance.on('blur', (event) => {
                    let field = event.fields[event.emittedBy];
                    switch (event.emittedBy) {
                        case "number":
                            this.invalidCard = !field.isValid;
                            break;
                        case "cvv":
                            this.invalidCvv = !field.isValid;
                            break;
                        case "expirationDate":
                            this.invalidExpiration = !field.isValid;
                            break;
                        case "postalCode":
                            this.invalidPostal = !field.isValid;
                    }
                });
                hostedFieldsInstance.on('empty', (event) => {
                    let field = event.fields[event.emittedBy];
                    if(field.isEmpty) {
                        this.validCard = false;
                        this.card = ' '
                    }
                });
                hostedFieldsInstance.on('validityChange', (event)  => {
                    let field = event.fields[event.emittedBy];
                    if (! field.isValid) {
                        this.validCard = true
                    } else if (! field.isPotentiallyValid) {
                        this.validCard = true
                    } else {
                        this.validCard = false
                    }
                });
                if (fieldsError) {
                    // Handle error in Hosted Fields creation
                    this.errorMessage = 'There was an error setting up the hosted fields! Message: ' + fieldsError.message;
                    this.$emit('braintreeError', this.errorMessage);
                    return;
                } else {
                    this.hostedFieldsInstance = hostedFieldsInstance;
                }

            });
        },
        disable() {
            this.hostedFieldsInstance.setAttribute({
                field: 'number',
                attribute: 'disabled',
            });
            this.hostedFieldsInstance.setAttribute({
                field: 'cvv',
                attribute: 'disabled',
            });
            this.hostedFieldsInstance.setAttribute({
                field: 'expirationDate',
                attribute: 'disabled',
            });
            this.hostedFieldsInstance.setAttribute({
                field: 'postalCode',
                attribute: 'disabled',
            });
        },
        getState() {
            let state = this.hostedFieldsInstance.getState();

            let valid = Object.keys(state.fields).every(function (key) {
                return state.fields[key].isValid;
            });
            if(valid) {
                this.cardIsValid = true
            }
        },
        tokenize () {
            return new Promise((resolve) => {
                this.hostedFieldsInstance.tokenize({
                    cardholderName: this.cardholderName
                } , (tokenizeErr, payload) => {
                    if (tokenizeErr) {
                        this.invalidCard = true;
                        this.invalidCvv = true;
                        this.invalidExpiration = true;
                        this.invalidPostal = true;
                        this.errorMessage = 'There was an error tokenizing! Message: ' + tokenizeErr.message;
                        this.$emit('braintreeError', this.errorMessage);
                        this.isLoading = false;
                        return;
                    }
                    this.getState();
                    if(this.cardIsValid) {
                        // console.log('payload loaded')
                        this.tokenizePayload = payload;
                        this.token = payload.nonce
                        //emmit from parent employer
                        this.$emit('tokenLoaded', [{
                            token: payload
                        }]);

                    }
                    resolve(payload)
                })
            })

        },
        async updateCard() {
            this.isLoading = true;
            await this.tokenize();
            let user = JSON.parse(localStorage.getItem('user'));
            // console.log(this.tokenizePayload)
            axios.post(LOCAL_BASE_URL + this.url +'/card/card-update', {
                token: this.token
            },{
                headers: {
                    Authorization: 'Bearer ' + user.authorization.access_token
                }
            }).then(response => {
                this.$emit('cardUpdated', response.data);
                this.hostedFieldsInstance.clear('number');
                this.hostedFieldsInstance.clear('cvv');
                this.hostedFieldsInstance.clear('expirationDate');
                this.hostedFieldsInstance.clear('postalCode');
                this.cardHolderName = null,
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                this.$message.error({
                    message: error.response.data.message,
                    center: true,
                    duration: 0,
                    showClose: true
                });
            })
        },
    }
}
</script>
<style lang="scss" >
    .braintree-hosted-fields-focused {
        transition: all 200ms ease;
        border-color: #E87722 !important;
    }
    #card-image {
        position: absolute;
        top: 46px;
        right: 1em;
        width: 44px;
        height: 28px;
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/346994/card_sprite.png);
        background-size: 86px 458px;
        border-radius: 4px;
        background-position: -100px 0;
        background-repeat: no-repeat;
        margin-bottom: 1em;
        &.visa {
            background-position: 0 -398px;
        }
        &.master-card {
            background-position: 0 -281px;
        }
        &.american-express {
            background-position: 0 -370px;
        }
        &.discover {
            background-position: 0 -163px;
        }
        &.maestro {
            background-position: 0 -251px;
        }
        &.jcb {
            background-position: 0 -221px;
        }
        &.diners-club {
            background-position: 0 -133px;
        }
    }
    .form-error {
        color: #F56C6C;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 90%;
        left: 0;
    }
    .card-input {
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 100%;
    }
    #expiration-month {
        border-right: none;
    }

    .braintree-hosted-fields-invalid {
        border-color: #EB5757;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #E87722 !important;
        background-color: #E87722 !important;
    }
    .el-button.is-plain:focus, .el-button.is-plain:hover, .el-radio__input.is-checked+.el-radio__label{
        border-color: #E87722 !important;
        color: #E87722 !important;
    }
</style>
