<template>
    <div>
        <div v-if="subscription.subscription_status === 'paused'">
            <div class="description">
                <h5 class="orange-text bold mb-3">Resume my Membership</h5>
                <el-row :gutter="15" type="flex">
                    <el-col>
                        <p class="small mt-1 mb-0 gray-text">You will be able to use... until your subscription expires at the end of the current subscription period.</p>
                        <p class="small mt-1 mb-0 gray-text">You will still have access to free features with corresponding limitations</p>
                        <p class="small mt-1 mb-0 gray-text">You can resume your subscription at anytime.</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col class="align-self-center text-right" >
                        <el-button type="warning" class="mt-4 bold" size="small" @click.prevent="resumeSubscription">Resume Subscription</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div  v-if="subscription.subscription_status === 'active'">
            <h5 class="orange-text bold mb-3">Pause my Membership</h5>
            <div class="description">
                <el-row :gutter="15" type="flex">
                    <el-col>
                        <p class="small mt-1 mb-0 gray-text">You will be able to use... until your subscription expires at the end of the current subscription period.</p>
                        <p class="small mt-1 mb-0 gray-text">You will still have access to free features with corresponding limitations</p>
                        <p class="small mt-1 mb-0 gray-text">You can resume your subscription at anytime.</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col class="align-self-center text-right" >
                        <el-button type="success" class="mt-4" @click.prevent="pauseSubscription">Pause Subscription</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div  v-if="subscription.subscription_status === 'canceled'">

        </div>
    </div>
</template>
<script>
import axios from "axios";
import {LOCAL_BASE_URL} from "../vuex/utils/constant";
let user = JSON.parse(localStorage.getItem('user'));
export default {
    props: {
        url: {
            type: String,
            required: true
        },
        subscription: {
            type: Object,
            required: true
        }
    },
    methods: {
        pauseSubscription() {
            axios.post(LOCAL_BASE_URL + this.url +'/pause/subscription', {
                subscription: this.subscription
            },{
                headers: {
                    Authorization: 'Bearer ' + user.authorization.access_token
                }
            }).then(response => {
                this.$emit('subscriptionUpdate', response.data)
            })
        },
        resumeSubscription() {
            axios.post(LOCAL_BASE_URL + this.url +'/resume/subscription', {
                subscription: this.subscription
            },{
                headers: {
                    Authorization: 'Bearer ' + user.authorization.access_token
                }
            }).then(response => {
                this.$emit('subscriptionUpdate', response.data)
            })
        }
    }
}
</script>
