<template>
    <div class="el-form">
        <!-- Please show only if there is already existing information-->
        <!-- <p class="small mt-1 mb-0 gray-text">Credit Card Type: {{currentCard.card_brand}}</p>
        <p class="small mt-1 mb-4 gray-text">Card No: xxxx-xxxx-xxxx-{{currentCard.card_last_digits}}</p> -->
        <loading-page :active.sync="isLoading" />
        <el-form ref="form" >
            <el-row :gutter="20" id="payment-form">
                <el-col :xs="24" :md="24" class="toggle-btns">
                    <!-- <p class="text-center ribbon">Great news! We are offering <span class="bold">75% off</span> to the next 500 employers!</p> -->
                    <ul class="tab payment-tab p-0 text-center">
                        <li>
                            <input id="monthly"
                                   type="radio"
                                   v-model="subscription.subscription_type"
                                   value="monthly"
                                   name="payment_type"
                                   required="">
                            <label for="monthly" class="">
                                <!-- <span class="h2 strikethrough small">$69</span> <span class="h2 bold">$17.25</span> -->
                                <span class="h2 bold">$69</span>
                                <br>
                                <span class="small bold">Monthly</span></label>
                        </li>
                        <li>
                            <input id="yearly"
                                   type="radio"
                                   v-model="subscription.subscription_type"
                                   value="yearly"
                                   name="payment_type"
                                   required="">
                            <label for="yearly" style="" class="">
                                <!-- <span class="h2 strikethrough small">$690</span> <span class="h2 bold">$172.50</span> -->
                                <span class="h2 bold">$690</span>
                                <br>
                                <span class="small bold">Yearly</span>
                            </label>
                        </li>
                    </ul>
                </el-col>
            </el-row>
            <h5 class="orange-text bold mb-2 mt-2 d-block text-left">Card Information</h5>
            <el-row :gutter="20">
                <el-col :xs="24">
                    <el-form-item label="Name on Card" prop="card_name">
                        <el-input v-model="cardHolderName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="Credit Card Number" prop="card_number">
                        <div class="card-input" id="card-number"></div>
                        <div class="form-error" v-show="invalidCard">
                            Invalid card.
                        </div>
                        <div id="card-image" :class="cardType"></div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="24" :md="8">
                    <el-form-item label="Expiry Date"  class="card-info">
                        <div class="card-input" id="expiration-date"></div>
                        <div class="form-error" v-show="invalidExpiration">
                            Invalid expiration date.
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :md="8">
                    <el-form-item label="CVV" class="card-info" >
                        <div class="card-input" id="cvv"></div>
                        <div class="form-error" v-show="invalidCvv">
                            Invalid CVV.
                        </div>
                    </el-form-item>
                </el-col>
                <!-- Remove postal code here and use the one from Billing -->
                <el-col :xs="24" :md="8">
                    <el-form-item label="Postal Code" prop="postal_code">
                        <div class="card-input" id="postal-code"></div>
                        <div class="form-error" v-show="invalidPostal">
                            Invalid Postal.
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
<!--            <el-row :gutter="20" v-show="currentCard &&-->
<!--            !modal">-->
<!--                <el-col :xs="24" class="text-right mt-3 mb-3">-->
<!--                    <el-button type="success" class="" @click.prevent="updateCard">Update Card</el-button>-->
<!--                </el-col>-->
<!--            </el-row>-->
        </el-form>

        <h5 class="mb-2 mt-3 orange-text bold d-block text-left">Billing Information</h5>
        <el-form :label-position="labelPosition" class="mt-4 text-left">
            <el-row type="flex" justify="center">
                <el-col :xs="24">
                    <el-checkbox class="mb-3"
                                 v-model="copyAddress">Same
                                                     as Company Address</el-checkbox>
                    <el-form-item label="Address 1" prop="address_1">
                        <el-input
                                v-model="signup.billing_information.address_1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row type="flex" justify="center">
                <el-col :xs="24">
                    <el-form-item label="Address 2" prop="address_2">
                        <el-input
                                v-model="signup.billing_information.address_2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12">
                    <el-form-item label="State" prop="state">
                        <el-input
                                v-model="signup.billing_information.state"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <el-form-item label="Zip Code" prop="zip">
                        <el-input
                                v-model="signup.billing_information.zip"></el-input>
                    </el-form-item>
                </el-col>
<!--                    <el-col :xs="24" :md="12">-->
<!--                        <el-form-item label="Postal Code" prop="postal_code">-->
<!--                            <div class="card-input" id="postal-code"></div>-->
<!--                            <div class="form-error" v-show="invalidPostal">-->
<!--                                Invalid Postal.-->
<!--                            </div>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
            </el-row>

            <el-row :gutter="20" type="flex"
                    justify="center">
                <el-col :xs="24" :md="12">
                    <el-form-item
                            label="Country" prop="country" :rules="[{ required: true, message: ' '}]">
                        <el-select v-model="signup.billing_information.country" filterable
                                   placeholder="Select" class="w-100">
                            <el-option
                                    v-for="item in countries"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :md="12">
                    <el-form-item label="City"
                                  prop="city">
                        <el-input
                                v-model="signup.billing_information.city"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-alert v-if="companyBillingErr"
                      type="error"
                      title="The following is required:"
                      :closable="click=false"
                      center="center"
                      @close="true"
                      class="signup-alert"
                      id="billing_info_error">
                <ul>
                    <li v-for="ccInfo in
                        companyBillingErr['billing_information.cc_info']" :key="ccInfo">
                        <i class="el-icon-error"></i>
                        {{ ccInfo }}
                    </li>
                    <li v-for="ccName in companyBillingErr['billing_information.cc_name']" :key="ccName">
                        <i class="el-icon-error"></i> {{ ccName }}
                    </li>
                    <li v-for="ccExpiry in companyBillingErr['billing_information.cc_expiry_date']" :key="ccExpiry">
                        <i class="el-icon-error"></i> {{ ccExpiry }}
                    </li>
                    <li v-for="ccCvv in companyBillingErr['billing_information.cc_cvv']" :key="ccCvv">
                        <i class="el-icon-error"></i> {{ ccCvv }}
                    </li>
                    <li v-for="addr1 in companyBillingErr['billing_information.address_1']" :key="addr1">
                        <i class="el-icon-error"></i> {{ addr1 }}
                    </li>
                    <li v-for="addr2 in companyBillingErr['billing_information.address_2']" :key="addr2">
                        <i class="el-icon-error"></i> {{ addr2 }}
                    </li>
                    <li v-for="zip in companyBillingErr['billing_information.zip']" :key="zip">
                        <i class="el-icon-error"></i> {{ zip }}
                    </li>
                    <li v-for="state in companyBillingErr['billing_information.state']" :key="state">
                        <i class="el-icon-error"></i> {{ state }}
                    </li>
                    <li v-for="city in
                    companyBillingErr['billing_information.city']" :key="city">
                        <i class="el-icon-error"></i> {{
                                                      city }}
                    </li>
                    <li v-for="title in companyBillingErr['billing_information.title']" :key="title">
                        <i class="el-icon-error"></i> {{ title }}
                    </li>
                    <li v-for="country in companyBillingErr['billing_information.country']" :key="country">
                        <i class="el-icon-error"></i> {{ country }}
                    </li>
                </ul>
            </el-alert>
        </el-form>
        <div class="text-right buttons pb-0">
            <el-button type="success" @click="subscribe">
                Update Subscription
            </el-button>
        </div>
    </div>
</template>

<script>
    import axios from "../client";
    import {client, hostedFields} from "braintree-web";
    import {LOCAL_BASE_URL, SUBSCRIBE, BILLING_TOKEN} from
            '../vuex/utils/constant'
    import LoadingPage from "./LoadingPage";
    import {mapGetters} from 'vuex'
    import PostAffiliatePro from '~/services/PostAffiliatePro'
    import getToken from '~/helpers/getToken'

    export default {
        name : "UpdateSubscription",

        components: {
            LoadingPage
        },

        props: {
            subscription: {
                type: Object
            },
            currentCard: {
                type: Object,
            },
        },

        data() {
            return {
                isLoading: false,
                countries: require('~/data/countries').default,
                cardName: '',
                vaultedPaymentMethods: null,
                companyBillingErr: null,
                hostedFieldsInstance: null,
                tokenizePayload: null,
                card: '',
                invalidCard: false,
                invalidCvv: false,
                invalidExpiration: false,
                invalidPostal: false,
                cardIsValid: false,
                cardHolderName: null,
                token: null,
                labelPosition: 'top',
                copyAddress: false,
                signup: {
                    billing_information: {
                        address_1:'',
                        address_2:'',
                        zip:'',
                        country:'',
                        state:'',
                    },
                },
                parent: null
            }
        },

        computed: {
            cardType() {
                return this.card
            },

            email() {
                return this.info.email
            },

            ...mapGetters({
                info:'getEmployerInfo'
                , error: 'getEmployerErrResp'
                , success: 'getEmployerSuccessResp'
                , status: 'authStatus'
            }),
        },

        watch: {
            copyAddress(newValue){
                if (newValue) {
                    let companyProfile = this.info.company
                    // right before overwriting the billing address, we save the original values
                    this.originalBillingAddress = {...this.signup.billing_information};
                    this.signup.billing_information = {
                        address_1: companyProfile.address_1,
                        address_2: companyProfile.address_2,
                        state: companyProfile.state,
                        zip: companyProfile.zip,
                        country: companyProfile.country,
                        city: companyProfile.city
                    }
                } else {
                    // revert to original values
                    this.signup.billing_information = {...this.originalBillingAddress}
                }
            }
        },

        mounted() {
            this.$store.dispatch('employerGet');
            this.init();
            this.copyAddress = true;
        },

        methods: {
            async subscribe() {
                this.companyBillingErr = null;
                this.isLoading = true;
                if(this.isSubscribed) {
                    ++this.$parent.step
                } else {
                    await this.tokenize();
                    // console.log(this.tokenizePayload)
                    axios.post(LOCAL_BASE_URL + SUBSCRIBE, {
                        ...this.signup,
                        ...this.subscription,
                        email: this.info.email,
                        company_type: 'employer',
                        //TODO change to dynamic
                        coupon: null,
                        plan_type: 'Silver',
                        token: this.token
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + getToken(),
                        },
                    }).then(response => {
                        console.log(response.data);
                        this.disable();
                        this.isLoading = false;
                        this.isSubscribed = true;
                        this.$emit('upgrade-subscription');
                        
                        // track affiliate sale
                        PostAffiliatePro.trackSubscription(response.data.subscription)

                        document.cookie = 'show_welcome=true; expires=Thu, 01 Jan 2100 00:00:00 UTC; path=/;';
                        this.$store.dispatch('subscription/checkSubscriptionName', true)
                    }).catch((error) => {
                        this.isLoading = false;
                        this.companyBillingErr =
                            error.response.data.errors;
                        // this.$message.error({
                        //     message: error.response.data.message,
                        //     center: true,
                        //     duration: 0,
                        //     showClose: true
                        // });
                    })
                }
            },

            init () {
                axios.get(LOCAL_BASE_URL + BILLING_TOKEN)
                    .then(response => {
                        client.create({
                            authorization: response.data.token
                        }, (clientErr, clientInstance) => {
                            if (clientErr) {
                                this.errorMessage = `There was an error setting up the client instance. Message: ${clientErr.message}`;
                                this.$emit('braintreeError', this.errorMessage);
                                return;
                            } else {
                                this.clientInstance = clientInstance
                                this.createField();
                            }
                        });
                    })
            },

            createField: function () {
                hostedFields.create({
                    client: this.clientInstance,
                    styles: {
                        'input, select': {
                            'border-radius': '4px',
                            'font-size': '14px',
                            'height': '40px',
                            'color': '#606266'
                        },
                        ':focus': {
                            'color': '#606266',
                        },
                        '.invalid': {
                            'color': '#EB5757'
                        }

                    },
                    fields: {
                        number: {
                            selector: '#card-number',
                            placeholder: '•••• •••• •••• ••••',
                        },
                        cvv: {
                            selector: '#cvv',
                        },
                        expirationDate: {
                            selector: '#expiration-date',
                            placeholder: 'MM/YY'
                        },
                        postalCode: {
                            selector: '#postal-code',
                        }
                    }
                }, (fieldsError, hostedFieldsInstance) => {
                    hostedFieldsInstance.on('cardTypeChange', (event) => {
                        try {
                            this.card = event.cards[0].type;
                            // this.cardName = event.cards[0].niceType;
                        } catch (e) {
                            this.card = '';
                            // this.cardName = '';
                        }

                    });
                    hostedFieldsInstance.on('blur', (event) => {
                        let field = event.fields[event.emittedBy];
                        switch (event.emittedBy) {
                            case "number":
                                this.invalidCard = !field.isValid;
                                break;
                            case "cvv":
                                this.invalidCvv = !field.isValid;
                                break;
                            case "expirationDate":
                                this.invalidExpiration = !field.isValid;
                                break;
                            case "postalCode":
                                this.invalidPostal = !field.isValid;
                        }
                    });
                    hostedFieldsInstance.on('empty', (event) => {
                        let field = event.fields[event.emittedBy];
                        if(field.isEmpty) {
                            this.validCard = false;
                            this.card = ' '
                        }
                    });
                    hostedFieldsInstance.on('validityChange', (event)  => {
                        let field = event.fields[event.emittedBy];
                        if (! field.isValid) {
                            this.validCard = true
                        } else if (! field.isPotentiallyValid) {
                            this.validCard = true
                        } else {
                            this.validCard = false
                        }
                    });
                    if (fieldsError) {
                        // Handle error in Hosted Fields creation
                        this.errorMessage = 'There was an error setting up the hosted fields! Message: ' + fieldsError.message;
                        this.$emit('braintreeError', this.errorMessage);
                        return;
                    } else {
                        this.hostedFieldsInstance = hostedFieldsInstance;
                    }

                });
            },

            disable() {
                this.hostedFieldsInstance.setAttribute({
                    field: 'number',
                    attribute: 'disabled',
                });
                this.hostedFieldsInstance.setAttribute({
                    field: 'cvv',
                    attribute: 'disabled',
                });
                this.hostedFieldsInstance.setAttribute({
                    field: 'expirationDate',
                    attribute: 'disabled',
                });
                this.hostedFieldsInstance.setAttribute({
                    field: 'postalCode',
                    attribute: 'disabled',
                });
            },

            getState() {
                let state = this.hostedFieldsInstance.getState();

                let valid = Object.keys(state.fields).every(function (key) {
                    return state.fields[key].isValid;
                });
                if(valid) {
                    this.cardIsValid = true
                }
            },

            tokenize () {
                return new Promise((resolve) => {
                    this.hostedFieldsInstance.tokenize({
                        cardholderName: this.cardholderName
                    } , (tokenizeErr, payload) => {
                        if (tokenizeErr) {
                            this.invalidCard = true;
                            this.invalidCvv = true;
                            this.invalidExpiration = true;
                            this.invalidPostal = true;
                            this.companyBillingErr = {
                                'billing_information.cc_info'
                                    : [
                                    tokenizeErr.message
                                ]
                            };
                            // this.errorMessage = 'There was an error tokenizing! Message: ' + tokenizeErr.message;
                            this.$emit('braintreeError', this.errorMessage);
                            this.isLoading = false;
                            return;
                        }
                        this.getState();
                        if(this.cardIsValid) {
                            // console.log('payload loaded')
                            this.tokenizePayload = payload;
                            this.token = payload.nonce
                            //emmit from parent employer
                            this.$emit('tokenLoaded', [{
                                token: payload
                            }]);

                        }
                        resolve(payload)
                    })
                })

            },
        }
    }
</script>

<style scoped lang="scss">
    .toggle-btns ul.tab li input[type="radio"]:checked ~ label .strikethrough {
        color: white;
    }
    .toggle-btns .tab li .strikethrough {
        color: gray;
        display: block;
    }
    .ribbon {
        background: #E87722;
        padding: 8px 15px;
        color: white;
        border-radius: 5px;
        word-break: break-word;
    }

</style>